// ===========================\\
//  #Decades Clock
//============================\\

.clock {
    padding: 1rem 1.5rem 1.25rem;
    border: 1px solid white;
    font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #fff;

    &__element {
      display: block;
      width: fit-content;
    }

    &__time-left {
        display: inline-block;
        width: 3rem;
        margin-right: 0.5rem;
        text-align: right;
    }
  }