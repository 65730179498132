@use './variables' as *;

// ===========================\\
//  #Media query mixins
//============================\\

@mixin large-mobile {
    @media screen and (min-width:490px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width:768px) {
        @content;
    }
}

@mixin large-tablet {
    @media screen and (min-width:1024px) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width:1130px) {
        @content;
    }
}
