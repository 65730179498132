// ===========================\\
//  #Colour variables
//============================\\

$color-ink: #2F4B7A;
$color-cherry: #A0312F;
$color-butter: #FAD260;
$color-lichen: #A9B978;


// ===========================\\
//  #Layout variables
//============================\\

$content-max-width: 1080px;